export enum JobTypeEnum {
	AUTO_EMOJI = 'AUTO_EMOJI',
	AUTO_PICTURE_AND_DESCRIPTION = 'AUTO_PICTURE_AND_DESCRIPTION',
	IMPORT_BOOKMARKS = 'IMPORT_BOOKMARKS',
	IMPORT_CONTACTS = 'IMPORT_CONTACTS',
	IMPORT_CSV = 'IMPORT_CSV',
	FILE_IMAGE = 'FILE_IMAGE',
	FILE_METADATA = 'FILE_METADATA',
	POCKET_IMPORT_BOOKMARKS = 'POCKET_IMPORT_BOOKMARKS',
	REACH_VISION = 'REACH_VISION',
	ZAPIER_CREATE_NOTE_ACTION = 'ZAPIER_CREATE_NOTE_ACTION',
	ZAPIER_CREATE_TAG_ACTION = 'ZAPIER_CREATE_TAG_ACTION',
	ZAPIER_CREATE_WEBSITE_ACTION = 'ZAPIER_CREATE_WEBSITE_ACTION',
	ZAPIER_CREATE_WORKSPACE_ACTION = 'ZAPIER_CREATE_WORKSPACE_ACTION',
	ZAPIER_CREATE_FILE_ACTION = 'ZAPIER_CREATE_FILE_ACTION',
	ZAPIER_CREATE_ALBUM_ACTION = 'ZAPIER_CREATE_ALBUM_ACTION',
	MAP_THUMBNAIL = 'MAP_THUMBNAIL',
	FILE_AUDIO_TRANSCRIPTION = 'FILE_AUDIO_TRANSCRIPTION',
	SAVED_LOCATION = 'SAVED_LOCATION',
	WEBSITE_METADATA = 'WEBSITE_METADATA',
	IMPORT_EVERNOTE = 'IMPORT_EVERNOTE',
	NODE_SUBTYPES_SETTINGS_NODES_TO_CREATE = 'NODE_SUBTYPES_SETTINGS_NODES_TO_CREATE',
	IMPORT_SHARE_NODES = 'IMPORT_SHARE_NODES',
	EXPORT_SHARE_NODES = 'EXPORT_SHARE_NODES',
	TAKEOUT_DATA = 'TAKEOUT_DATA',
	GOOGLE_DOCS = 'GOOGLE_DOCS',
	DROPBOX_PAPER = 'DROPBOX_PAPER',
	IMPORT_DOCX = 'IMPORT_DOCX',
	IMPORT_MARKDOWN = 'IMPORT_MARKDOWN',
	QUIP = 'QUIP',
	GOOGLE_DRIVE = 'GOOGLE_DRIVE',
	LEARN_NODES = 'LEARN_NODES',
	RECOMMENDED_TAGS = 'RECOMMENDED_TAGS',
	ASK_CHAT_KNOWLEDGE = 'ASK_CHAT_KNOWLEDGE',
	ASK_CHAT_WIDGET = 'ASK_CHAT_WIDGET',
	CHAT_WIDGET_DATA_EXPORT = 'CHAT_WIDGET_DATA_EXPORT',
	CHAT_WIDGET_EMAIL_NOTIFICATION = 'CHAT_WIDGET_EMAIL_NOTIFICATION',
	IMPORT_NODES = 'IMPORT_NODES',
	EXPORT_NODES = 'EXPORT_NODES',
	INFERRED_DESCRIPTION = 'INFERRED_DESCRIPTION',
	WEBSITE_IMPORT = 'WEBSITE_IMPORT',
	MEDIA_PREVIEW = 'MEDIA_PREVIEW',
	SITEMAP = 'SITEMAP',
	SYNC_INTEGRATION = 'SYNC_INTEGRATION',
}
