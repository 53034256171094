import { PipelineInterface, PipelinePayloadInterface } from '../../pipelines';
import { IntegrationExecutionInterface } from '../integration-execution.interface';
import { SitemapIntegrationInterface } from './sitemap-integration.interface';

export interface SitemapScanResponseInterface {
	urls: { url: string; lastModification?: string }[];
}

export interface SitemapRegisterResponseInterface {
	integration: SitemapIntegrationInterface;
	execution: {
		execution?: IntegrationExecutionInterface | null;
		pipeline?: PipelineInterface;
		payload?: PipelinePayloadInterface;
	};
}

export interface SitemapSelectionResponseInterface {
	selection: string[];
}

export type SitemapSyncResponseInterface = {
	execution: IntegrationExecutionInterface;
	pipeline?: PipelineInterface;
	payload: PipelinePayloadInterface;
};
