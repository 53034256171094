import { RegisterMethod } from '../auth';
import { ClientMetadataIsNative, ClientMetadataOs, ClientMetadataSource } from '../client-metadata';
import { RankKeyEnum } from '../ranks';
import { AdminFeatureFlags, CountryCode, Gender, Interest, Profession } from '../user';

export interface StatisticTimeRangeInterface {
	from?: Date;
	to?: Date;
}

export interface StatisticNumberRangeInterface {
	min?: number;
	max?: number;
}

export interface OccurrenceStatisticsUserFilterInterface {
	countries?: CountryCode[];
	professions?: Profession[];
	interests?: Interest[];
	genders?: Gender[];
	ranks?: RankKeyEnum[];
	registerMethods?: RegisterMethod[];
	registeredNatives?: ClientMetadataIsNative[];
	registeredOSs?: ClientMetadataOs[];
	registeredSources?: ClientMetadataSource[];
	adminFeatureFlags?: AdminFeatureFlags[];
	activatedAt?: StatisticTimeRangeInterface;
	registeredAt?: StatisticTimeRangeInterface;
	birthday?: StatisticTimeRangeInterface;
}
