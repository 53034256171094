import { IntegrationBaseInterface } from '../integration-base.interface';
import { SitemapEntryInterface } from './sitemap-integration-entry.interface';

export interface SitemapScanRequestInterface {
	url: string;
}

export interface SitemapRegisterRequestInterface {
	sitemap: string;

	entries: SitemapEntryInterface[];
}

export interface SitemapSelectionRequestInterface {
	integrationId: IntegrationBaseInterface['id'];
}

export type SitemapSyncRequestInterface = SitemapSelectionRequestInterface;
