import { Nullable } from '../typescript';

export type ChatWidgetSessionUsageInterface = {
	id: string;
	widgetId: string;
	sessionId: string;
	createdAt: number;
	lastAccess: number;
	lastAsked: Nullable<number>;
	location: Nullable<string>;
	messagesCount: number;
	unreadMessagesCount: number;
	upvotesCount: number;
	unreadUpvotesCount: number;
	downvotesCount: number;
	unreadDownvotesCount: number;
	contactedSupportCount: number;
	unreadContactedSupportCount: number;
	categories: string[];
	resolvedCount: number;
	unresolvedCount: number;
	unreadResolvedCount: number;
	unreadUnresolvedCount: number;
};

export type UpdateSessionUsageInterface = Partial<
	Omit<
		ChatWidgetSessionUsageInterface,
		| 'id'
		| 'widgetId'
		| 'sessionId'
		| 'createdAt'
		| 'messagesCount'
		| 'unreadMessagesCount'
		| 'upvotesCount'
		| 'unreadUpvotesCount'
		| 'downvotesCount'
		| 'unreadDownvotesCount'
		| 'contactedSupportCount'
		| 'unreadContactedSupportCount'
		| 'unreadResolvedCount'
		| 'unreadUnresolvedCount'
		| 'resolvedCount'
		| 'unresolvedCount'
		| 'location'
	>
> & {
	locationCity?: string;
	locationCountry?: string;
};
