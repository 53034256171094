import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
	ActionPerformed,
	PushNotifications,
	PushNotificationSchema,
	Token,
} from '@capacitor/push-notifications';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MarketingDevicePlatform } from '@reach/interfaces';
import { ensureAssert } from '@reach/utils';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { DevicesApiService } from '~app-client/api/services';
import { ClientPlatform } from '../types/platform';
import { ClientService } from './client';

enum DeviceTokenState {
	LOADING = 'LOADING',
	LOADED = 'LOADED',
	ERROR = 'ERROR',
}

/* @dynamic */
/**
 * Service that handles all the logic regarding Firebase Devices.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class FirebaseDeviceService {
	private readonly deviceTokenState$$ = new BehaviorSubject<{
		state: string;
		token: string | null;
	}>({
		state: Capacitor.isNativePlatform() ? DeviceTokenState.LOADING : DeviceTokenState.ERROR,
		token: null,
	});
	public deviceTokenState$ = this.deviceTokenState$$.asObservable();

	private readonly devicesApi = inject(DevicesApiService);
	private readonly clientService = inject(ClientService);

	constructor() {
		if (Capacitor.isNativePlatform()) {
			// On success, we should be able to receive notifications
			PushNotifications.addListener('registration', (token: Token) => {
				console.log('Push registration success', token.value);
				this.deviceTokenState$$.next({
					state: DeviceTokenState.LOADED,
					token: token.value,
				});
			});

			// Some issue with our setup and push will not work
			PushNotifications.addListener('registrationError', (error: unknown) => {
				console.log('Error on push registration');
				console.error(error);
				this.deviceTokenState$$.next({
					state: DeviceTokenState.ERROR,
					token: null,
				});
			});

			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener(
				'pushNotificationReceived',
				async (notification: PushNotificationSchema) => {
					console.log('Push received', notification);
					try {
						await this.devicesApi.trackPush({
							delivery_id: notification.data['CIO-Delivery-ID'],
							event: 'delivered',
							device_id: notification.data['CIO-Delivery-Token'],
							timestamp: Date.now(),
						});
					} catch (e) {
						console.error(e);
					}
				}
			);

			// Method called when tapping on a notification
			PushNotifications.addListener(
				'pushNotificationActionPerformed',
				async (action: ActionPerformed) => {
					// this.doSomethingWithNotification(action.notification.data);
					try {
						console.log('Push action performed', action);
						await this.devicesApi.trackPush({
							delivery_id: action.notification.data['CIO-Delivery-ID'],
							event: 'delivered',
							device_id: action.notification.data['CIO-Delivery-Token'],
							timestamp: Date.now(),
						});

						await this.devicesApi.trackPush({
							delivery_id: action.notification.data['CIO-Delivery-ID'],
							event: 'opened',
							device_id: action.notification.data['CIO-Delivery-Token'],
							timestamp: Date.now(),
						});
					} catch (e) {
						console.error(e);
					}
				}
			);

			// Request permission to use push notifications
			// iOS will prompt user and return if they granted permission or not
			// Android will just grant without prompting
			PushNotifications.requestPermissions().then((result) => {
				if (result.receive === 'granted') {
					// Register with Apple / Google to receive push via APNS/FCM
					PushNotifications.register();
				} else {
					// Show some error
					this.deviceTokenState$$.next({
						state: DeviceTokenState.ERROR,
						token: null,
					});
				}
			});
		}
	}

	public init(): void {
		// no need to do anything here
	}

	public async addDevice(): Promise<void> {
		const platform = this.clientService.platform;
		const marketingPlatform: MarketingDevicePlatform | null =
			platform === ClientPlatform.IOS
				? MarketingDevicePlatform.IOS
				: platform === ClientPlatform.ANDROID
				? MarketingDevicePlatform.ANDROID
				: null;

		const token = await this.getDeviceToken();

		if (ensureAssert(marketingPlatform) && ensureAssert(token)) {
			await this.devicesApi.addDevice({
				id: token,
				platform: marketingPlatform,
			});
		}
	}

	public async removeDevice(): Promise<void> {
		const token = await this.getDeviceToken();
		if (token) {
			await this.devicesApi.removeDevice(token);
		}
	}

	public async getDeviceToken(): Promise<string | null> {
		const value = await firstValueFrom(
			this.deviceTokenState$.pipe(
				filter((state) => {
					return state.state !== DeviceTokenState.LOADING;
				})
			)
		);

		return value.state === DeviceTokenState.LOADED ? value.token : null;
	}
}
