<ng-template #tpl>

	<ng-container *transloco="let translate">

		<div
            *ngIf="{
                quota: quota$ | async
            } as vm"
            class="talign-c"
        >

			<div class="w-100 m-1-tb">
                <img
                    src="assets/images/modals/nodes-quota.svg"
                    style="height: 120px;"
                >
                <div class="fs-s fc-warn m-1-t">
                    <span *ngIf="vm.quota">
                        {{ vm.quota?.used || 0 }}/{{ vm.quota?.total || 0 }}
                    </span>

                    <span *ngIf="!vm.quota">
                        &nbsp;
                    </span>
                </div>
            </div>

			<p class="fw-bold fs-icon talign-c">
				{{ translate('errorModals.nodes_quota.title') }}
			</p>

			<p class="m-3-tb">
				{{ translate('errorModals.nodes_quota.description') }}
			</p>

			<button
				class="w-100 p-1q-tb p-3-rl border-box bg-primary fw-bold fc-white"
				style="border-radius: var(--spacing);"
				(click)="confirm()"
			>
				{{ translate('errorModals.quota_common.check') }}
			</button>

			<button
				class="w-100 p-1q-tb p-3-rl border-box m-1-t fc-400"
				(click)="cancel()"
			>
				{{ translate('general.cancel') }}
			</button>

		</div>

	</ng-container>

</ng-template>
