<div
	class="wrapper"
	[class.logo-loaded]="logoLoaded$ | async"
	[class.loader-visible]="loaderVisible$ | async"
	[class.app-initialized]="animationFinished$ | async"
	[class.prepare-restart]="prepareRestart$ | async"
	[class.show-progress]="showProgress$ | async"
	[class.show-label]="showUpdatingLabel$ | async"
>

	<div
		class="loader-wrapper"
		style="max-width: 80%;"
	>
		<img
			class="left-as-is"
			style="width: 205px; height: 40px;"
			src="/assets/images/logo/logo-white-with-text.png"
			alt="myReach"
			width="205"
			height="40"
			(load)="logoLoaded()"
		>

		<div
			class="pos-abs pos-b-0 pos-l-0 w-100 p-3-rl border-box fl fl-ai-c fl-jc-c"
			style="transform: translateY(100%);"
		>
			<div class="loader-indicator"></div>
			<div class="updating-label">
				Updating...
			</div>
		</div>
	</div>

	<div class="transition-wrapper"></div>

</div>
