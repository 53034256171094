import { Provider } from '@angular/core';
import {
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalService,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
} from '@azure/msal-angular';
import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication,
	PublicClientApplication,
} from '@azure/msal-browser';

const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(version: string): IPublicClientApplication {
	const url = new URL(window.location.href);
	url.pathname = '/auth/sign-in';
	url.search = '';
	url.hash = '';
	return new PublicClientApplication({
		auth: {
			clientId: '3361613e-b173-498c-978f-20d709e6a56b',
			redirectUri: url.href,
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE,
		},
		telemetry: {
			application: {
				appName: 'myReach',
				appVersion: version,
			},
		},
	});
}

// MSAL Guard is required to protect routes and require authentication before accessing protected routes
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Popup,
		authRequest: {
			scopes: ['Profile', 'User.Read', 'ProfilePhoto.Read.All'],
		},
	};
}

export const provideMicrosoftAuthentication = (version: string): Provider[] => {
	return [
		{
			provide: MSAL_INSTANCE,
			useFactory: () => MSALInstanceFactory(version),
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
	];
};
