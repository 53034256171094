import { RegisterMethod } from '../auth/auth-requests.interfaces';
import { ClientMetadataIsNative, ClientMetadataOs } from '../client-metadata';
import { ReachIntegration } from '../integrations';
import { NodeType } from '../nodes/node-type.enum';
import { NoteTemplate } from '../note-templates/note-template.enum';
import {
	DateFormat,
	FontSizeEnum,
	HeaderColor,
	HighMediumLow,
	ScrollerOption,
	Theme,
	ThemeColor,
	TimeFormat,
	VeryhighToVerylow,
	VisualizerColorTheme,
} from '../settings';
import { Nullable } from '../typescript';
import { WikimediaLanguageCodeEnum } from '../wikimedia';
import { CountryCode } from './country.enum';
import { Gender } from './gender.enum';
import { Interest } from './interests.enum';
import { Language } from './language.enum';
import { DomainSettingsEnum } from './preferences.interface';
import { Profession } from './profession.enum';
import { UserRegisteredSource } from './user-registered-source.types';
import { UserType } from './user-type.enum';
export interface DeletedUserInterface {
	id: string;
	birthday: Nullable<Date>;
	country: Nullable<CountryCode>;
	profession: Nullable<Profession>;
	hearing: Nullable<string>;
	registerLocation: Nullable<string>;
	registerIP: Nullable<string>;
	registeredAt: Nullable<Date>;
	activatedAt: Nullable<Date>;
	lastAccess: Nullable<Date>;
	deletedAt: Date;
	type: UserType;
	gender: Nullable<Gender>;
	interests: Nullable<Interest[]>;
	selectedNoteTemplates: NoteTemplate[];
	couldLogInWithPassword: boolean;
	couldLogInWithGoogle: boolean;
	couldLogInWithApple: boolean;
	registerMethod: RegisterMethod;
	completedOnboarding: boolean;
	integrations: { [id in ReachIntegration]: number };

	registeredSource: UserRegisteredSource;
	registeredOS: ClientMetadataOs;
	registeredNative: ClientMetadataIsNative;
	nodeCountPerType: { [key in NodeType]: number };
	visualNodeCountPerType: { [key in NodeType]: number };
	deletionMotive: string[];
	theme: Theme;
	themeColor: ThemeColor;
	headerColor: HeaderColor;
	sidebar: HighMediumLow;
	sidebarOpened: boolean;
	scroller: ScrollerOption;
	language: Language;
	dateFormat: DateFormat;
	timeFormat: TimeFormat;
	occasionalSurveys: boolean;
	bugReporter: boolean;
	usageAnalysis: boolean;
	showTimePrefix: boolean;
	weekDayLongFormat: boolean;
	fontSize: FontSizeEnum;
	colorTheme: VisualizerColorTheme;
	domainCreation: DomainSettingsEnum;
	wikimediaLanguage: WikimediaLanguageCodeEnum;
	in3D: boolean;
	resolution: VeryhighToVerylow;
	smoothEdges: boolean;
	showAnimation: boolean;
	loadingGraphTime: number;
	relationshipStrength: VeryhighToVerylow;
	trackPath: boolean;
	nodeHover: boolean;
	glare: boolean;

	emailfeaturesAndAnnouncements: boolean;

	emailtipsAndRecommendations: boolean;

	emailcommunity: boolean;

	emailresearchAndFeedback: boolean;

	emailnewsletters: boolean;

	emaileventsAndMilestones: boolean;

	pushfeaturesAndAnnouncements: boolean;

	pushtipsAndRecommendations: boolean;

	pushcommunity: boolean;

	pushresearchAndFeedback: boolean;

	newsletters: boolean;

	eventsAndMilestones: boolean;
}
