import { BooleanEnum } from '../typescript';
import { JobStatusEnum } from './job-status.enum';
import { JobTypeEnum } from './job-type.enum';

export const jobJobTypesWithNodeIdMetadataSet = new Set([
	JobTypeEnum.AUTO_EMOJI,
	JobTypeEnum.AUTO_PICTURE_AND_DESCRIPTION,
	JobTypeEnum.FILE_IMAGE,
	JobTypeEnum.FILE_METADATA,
	JobTypeEnum.REACH_VISION,
	JobTypeEnum.MAP_THUMBNAIL,
	JobTypeEnum.FILE_AUDIO_TRANSCRIPTION,
	JobTypeEnum.SAVED_LOCATION,
	JobTypeEnum.WEBSITE_METADATA,
	JobTypeEnum.LEARN_NODES,
	JobTypeEnum.RECOMMENDED_TAGS,
	JobTypeEnum.INFERRED_DESCRIPTION,
]);

export interface GetNodeJobsRequest {
	returnLastJobs: BooleanEnum;
	jobType?: JobTypeEnum;
	status?: JobStatusEnum[];
}
