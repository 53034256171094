import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserPreferences } from '@reach/interfaces';
import { ReachTranslateService } from '~app-client-item/core/services/translate.service';
import { SettingsService } from '~app-client/core/services';

@UntilDestroy()
@Component({
	selector: 'settings-reactor',
	template: '',
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class SettingsReactorComponent implements OnInit {
	private readonly translateService = inject(ReachTranslateService);
	private readonly settingsService = inject(SettingsService);
	private readonly cdRef = inject(ChangeDetectorRef);

	constructor() {
		this.cdRef.detach();
	}

	ngOnInit(): void {
		this.settingsService.settings$.pipe(untilDestroyed(this)).subscribe((settings) => {
			this.handleSettings(settings);
		});
	}

	private handleSettings(settings: UserPreferences): void {
		this.translateService.setLanguage(settings.general.language);
	}
}
