export enum UserListTableKeys {
	DELETEDID = 'DELETEDID',
	DELETED_AT = 'DELETED_AT',
	PRIVATEID = 'PRIVATEID',
	FULLNAME = 'FULLNAME',
	USERNAME = 'USERNAME',
	EMAIL = 'EMAIL',
	PROFESSION = 'PROFESSION',
	HEARING = 'HEARING',
	BIRTHDAY = 'BIRTHDAY',
	COUNTRY = 'COUNTRY',
	GENDER = 'GENDER',
	INTERESTS = 'INTERESTS',
	TYPE = 'TYPE',
	ACTIVATEDAT = 'ACTIVATEDAT',
	REGISTEREDAT = 'REGISTEREDAT',
	SUBSCRIBEDAT = 'SUBSCRIBEDAT',
	LASTACCESS = 'LASTACCESS',
	REGISTEREDSOURCE = 'REGISTEREDSOURCE',
	REGISTEREDOS = 'REGISTEREDOS',
	REGISTEREDNATIVE = 'REGISTEREDNATIVE',
	REGISTERLOCATION = 'REGISTERLOCATION',
	REGISTERIP = 'REGISTERIP',
	REGISTEREDWITH = 'REGISTEREDWITH',
	PRIVACYPOLICYACCEPTEDDATE = 'PRIVACYPOLICYACCEPTEDDATE',
	PRIVACYPOLICYVERSION = 'PRIVACYPOLICYVERSION',
	PRIVACYPOLICYACCEPTEDIP = 'PRIVACYPOLICYACCEPTEDIP',
	TERMSANDCONDITIONSACCEPTEDDATE = 'TERMSANDCONDITIONSACCEPTEDDATE',
	TERMSANDCONDITIONSVERSION = 'TERMSANDCONDITIONSVERSION',
	TERMSANDCONDITIONSACCEPTEDIP = 'TERMSANDCONDITIONSACCEPTEDIP',
	ID = 'ID',
	IP = 'IP',
	BESTDESCRIBES = 'BESTDESCRIBES',
	STARTINGWORKSPACES = 'STARTINGWORKSPACES',
	ALL_NODES = 'ALL_NODES',
	VISUAL_NODES = 'VISUAL_NODES',
	ALL_WORKSPACES = 'ALL_WORKSPACES',
	VISUAL_WORKSPACES = 'VISUAL_WORKSPACES',
	ALL_NOTES = 'ALL_NOTES',
	VISUAL_NOTES = 'VISUAL_NOTES',
	ALL_WEBSITES = 'ALL_WEBSITES',
	VISUAL_WEBSITES = 'VISUAL_WEBSITES',
	ALL_FILES = 'ALL_FILES',
	VISUAL_FILES = 'VISUAL_FILES',
	ALL_TAGS = 'ALL_TAGS',
	VISUAL_TAGS = 'VISUAL_TAGS',
	ALL_ALBUMS = 'ALL_ALBUMS',
	VISUAL_ALBUMS = 'VISUAL_ALBUMS',
	COUNTGOOGLEPLACESDETAILSREQUEST = 'COUNTGOOGLEPLACESDETAILSREQUEST',
	COUNTGOOGLEPLACESSEARCHREQUEST = 'COUNTGOOGLEPLACESSEARCHREQUEST',
	TOTAL_INTEGRATIONS_COUNT = 'TOTAL_INTEGRATIONS_COUNT',
	CREDITS_AMOUNT = 'CREDITS_AMOUNT',
	UNCLAIMED_CREDITS_AMOUNT = 'UNCLAIMED_CREDITS_AMOUNT',
	LEVEL = 'LEVEL',
	RANK = 'RANK',
	NUM_EXPERIENCE_POINTS = 'NUM_EXPERIENCE_POINTS',
	FANATICBADGE = 'FANATICBADGE',
	CREATORBADGE = 'CREATORBADGE',
	NETWORKERBADGE = 'NETWORKERBADGE',
	RACERBADGE = 'RACERBADGE',
	UNSTOPPABLEBADGE = 'UNSTOPPABLEBADGE',
	GATHERERBADGE = 'GATHERERBADGE',
	WIZARDBADGE = 'WIZARDBADGE',
	COLLECTORBADGE = 'COLLECTORBADGE',
	MULTITASKERBADGE = 'MULTITASKERBADGE',
	PERFECTIONISTBADGE = 'PERFECTIONISTBADGE',
	LEARNERBADGE = 'LEARNERBADGE',
	INFLUENCERBADGE = 'INFLUENCERBADGE',
	PHOTOGENICBADGE = 'PHOTOGENICBADGE',
	CUSTOMISERBADGE = 'CUSTOMISERBADGE',
	SUPERSTARBADGE = 'SUPERSTARBADGE',
	GENIUSBADGE = 'GENIUSBADGE',
	MEMORYFILES = 'MEMORYFILES',
	MEMORYATTACHMENTS = 'MEMORYATTACHMENTS',
	MEMORYALBUMS = 'MEMORYALBUMS',
	MEMORYTOTAL = 'MEMORYTOTAL',
	CATEGORY = 'CATEGORY',
	DIFFICULTY = 'DIFFICULTY',
	TIME = 'TIME',
	QUESTIONS = 'QUESTIONS',
	CORRECT_ANSWER = 'CORRECT_ANSWER',
	CORRECT_ANSWER_2 = 'CORRECT_ANSWER_2',
	CORRECT_ANSWER_3 = 'CORRECT_ANSWER_3',
	CORRECT_ANSWER_4 = 'CORRECT_ANSWER_4',
	WRONG_ANSWER = 'WRONG_ANSWER',
	WRONG_ANSWER_2 = 'WRONG_ANSWER_2',
	WRONG_ANSWER_3 = 'WRONG_ANSWER_3',
	CORRECT_COUNT = 'CORRECT_COUNT',
	WRONG_COUNT = 'WRONG_COUNT',
	TIMEOUT_COUNT = 'TIMEOUT_COUNT',
	NUM_NODES_CREATED_LAST_DAY = 'NUM_NODES_CREATED_LAST_DAY',
	NUM_NODES_CREATED_LAST_WEEK = 'NUM_NODES_CREATED_LAST_WEEK',
	NUM_NODES_CREATED_LAST_MONTH = 'NUM_NODES_CREATED_LAST_MONTH',
	NUM_NODES_CREATED_LAST_QUARTER = 'NUM_NODES_CREATED_LAST_QUARTER',
	THEME = 'THEME',
	LANGUAGE = 'LANGUAGE',
	TYPEFORM_BEFORE_2023_USERS = 'TYPEFORM_BEFORE_2023_USERS',
	TYPEFORM_REGISTRATION = 'TYPEFORM_REGISTRATION',
	TYPEFORM_ADVENTURER = 'TYPEFORM_ADVENTURER',
	TYPEFORM_EXPLORER = 'TYPEFORM_EXPLORER',
	TYPEFORM_PIONEER = 'TYPEFORM_PIONEER',
	TYPEFORM_VISIONARY = 'TYPEFORM_VISIONARY',
	TYPEFORM_MASTER = 'TYPEFORM_MASTER',
	TYPEFORM_EXPERT = 'TYPEFORM_EXPERT',

	// Tag subtypes counts
	TAG_COMPANY_COUNT = 'TAG_COMPANY_COUNT',
	TAG_CONTACT_COUNT = 'TAG_CONTACT_COUNT',
	TAG_PLACE_COUNT = 'TAG_PLACE_COUNT',
	TAG_TOPIC_COUNT = 'TAG_TOPIC_COUNT',
	TAG_OTHER_COUNT = 'TAG_OTHER_COUNT',

	// Node source counts
	NUM_NODES_FROM_INTEGRATIONS = 'NUM_NODES_FROM_INTEGRATIONS',
	SOURCE_DEFAULT_CREATION = 'DEFAULT_CREATION',
	SOURCE_SHARE_CREATION = 'SHARE_CREATION',
	SOURCE_PLUGIN = 'PLUGIN',
	SOURCE_RECCOMENDATION_CREATION = 'RECCOMENDATION_CREATION',
	SOURCE_EXTRACTED_CANDIDATE = 'EXTRACTED_CANDIDATE',
	SOURCE_IMPORT = 'IMPORT',
	SOURCE_FAST_CREATION = 'FAST_CREATION',
	SOURCE_UNKNOWN = 'UNKNOWN',
	SOURCE_SAMPLE = 'SAMPLE',
	SOURCE_NODE_SUBTYPES_NODES_TO_CREATE = 'NODE_SUBTYPES_NODES_TO_CREATE',
	SOURCE_PROPERTY_VALUE = 'PROPERTY_VALUE',
	SOURCE_NOTE_FROM_TRANSCRIPTION = 'NOTE_FROM_TRANSCRIPTION',

	SOURCE_NOTE_ATTACHMENT = 'NOTE_ATTACHMENT',
	SOURCE_AUTO = 'AUTO',
	SOURCE_MOBILE = 'MOBILE',
	SOURCE_DESKTOP = 'DESKTOP',

	// Node source counts per integration type
	SOURCE_POCKET = 'POCKET',
	SOURCE_PHONE_CONTACTS = 'PHONE_CONTACTS',
	SOURCE_BOOKMARKS = 'BOOKMARKS',
	SOURCE_ZAPIER_ACTION = 'ZAPIER_ACTION',
	SOURCE_CSV = 'CSV',
	SOURCE_IMPORT_EVERNOTE = 'IMPORT_EVERNOTE',
	SOURCE_NOTE_FROM_WORD_DOCX = 'NOTE_FROM_WORD_DOCX',
	SOURCE_NOTE_FROM_MARKDOWN = 'NOTE_FROM_MARKDOWN',
	SOURCE_GOOGLE_DOCS = 'GOOGLE_DOCS',

	SOURCE_DROPBOX_PAPER = 'DROPBOX_PAPER',
	SOURCE_QUIP = 'QUIP',
	SOURCE_GOOGLE_DRIVE = 'GOOGLE_DRIVE',

	// browser source
	SOURCE_CHROME = 'CHROME',
	SOURCE_FIREFOX = 'FIREFOX',

	// Edges count

	TOTAL_EDGE_COUNT = 'totalEdges',
	TOTAL_REFERENCE_EDGE_COUNT = 'referencedEdges',
	TOTAL_DOMAIN_EDGE_COUNT = 'domainEdges',
	TOTAL_USER_EDGE_COUNT = 'userCreatedEdges',

	// Custom properties count

	TOTAL_CREATED_OR_MODIFIED_PROPERTIES_COUNT = 'totalCreatedOrModifiedProperties',
	TOTAL_CUSTOMIZED_PROPERTIES_CREATED_COUNT = 'totalNumberOfCustomizedPropertiesCreated',
	TOTAL_USAGE_OF_CUSTOMIZED_PROPERTIES_COUNT = 'totalUsageOfCustomizedProperties',

	// Communication types enabled
	COMMUNICATION_ALL_EMAIL_NOTIFICATIONS = 'COMMUNICATION_ALL_EMAIL_NOTIFICATIONS',
	COMMUNICATION_ALL_PUSH_NOTIFICATIONS = 'COMMUNICATION_ALL_PUSH_NOTIFICATIONS',
	COMMUNICATION_FEATURES_AND_ANNOUNCEMENTS = 'COMMUNICATION_FEATURES_AND_ANNOUNCEMENTS',
	COMMUNICATION_TIPS_AND_RECOMMENDATIONS = 'COMMUNICATION_TIPS_AND_RECOMMENDATIONS',
	COMMUNICATION_COMMUNITY = 'COMMUNICATION_COMMUNITY',
	COMMUNICATION_RESEARCH_AND_FEEDBACK = 'COMMUNICATION_RESEARCH_AND_FEEDBACK',
	COMMUNICATION_NEWSLETTERS = 'COMMUNICATION_NEWSLETTERS',
	COMMUNICATION_EVENTS_AND_MILESTONES = 'COMMUNICATION_EVENTS_AND_MILESTONES',

	// AI USAGE
	AI_CHAT_NUM_CHARS = 'AI_CHAT_NUM_CHARS',
	AI_CHAT_NUM_WORDS = 'AI_CHAT_NUM_WORDS',
	AI_CHAT_NUM_MESSAGES = 'AI_CHAT_NUM_MESSAGES',
}
