export interface GoogleDriveIntegrationFilesImportInterface {
	files: GoogleDriveIntegrationFileImportInterface[];
	rootId: string;
}

export interface GoogleDriveFolderPathInterface {
	id: string;

	name: string;
}

export interface GoogleDriveIntegrationFileImportInterface {
	fileId: string;

	folderPath?: GoogleDriveFolderPathInterface[];

	isContainer: boolean;
}
