import { Injectable } from '@angular/core';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import {
	CompleteRegistrationBody,
	CompleteRegistrationResponse,
	Language,
	LoginResponse,
	LogoutResponse,
	ReachHeaderEnum,
	RegisterBody,
	RegisterResponse,
	UserInPing,
	UserWithoutSensitive,
} from '@reach/interfaces';
import { decodeHeaderPayload } from '@reach/utils';
import { BaseApiService } from '~app-client/api/_internal';
import { userTransformer } from '~app-client/core/transformers';
import { ClientUser } from '~app-client/core/types';

export interface SocialUserRequest {
	email: string;
	name: string;
	idToken: string;
	photoUrl: string;
	category?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthApiService extends BaseApiService {
	protected commonPath = ['auth'];

	public async ping(): Promise<ClientUser> {
		const params = { t: this.paramTime };

		const res = await this.http.getWithHeaders<UserInPing>(this.url('ping'), params);

		if (res.body === null) {
			throw 'empty body';
		}

		const impersonatingValue = decodeHeaderPayload(
			res.headers.get(ReachHeaderEnum.HEADER_REACH_IMPERSONATING) || ''
		);
		const impersonating =
			typeof impersonatingValue === typeof true ? (impersonatingValue as boolean) : false;

		return userTransformer(res.body, { impersonating });
	}

	public login(usernameOrEmail: string, password: string): Promise<ClientUser> {
		return this.http
			.post<LoginResponse>(this.url('login'), {
				username: usernameOrEmail,
				password,
			})
			.then(userTransformer);
	}

	public register(user: RegisterBody): Promise<ClientUser> {
		const body = user;
		return this.http.post<RegisterResponse>(this.url('register'), body).then(userTransformer);
	}

	public resetPassword(email?: string): Promise<void> {
		const body = { email };
		return this.http.post<void>(this.url('password-recovery'), body);
	}

	public changePassword(newPassword: string, token: string): Promise<void> {
		return this.http.patch<void>(
			this.url('password'),
			{ newPassword },
			{},
			{ ['x-access-token']: token }
		);
	}

	public logout(): Promise<LogoutResponse> {
		return this.http.post<LogoutResponse>(this.url('logout'));
	}

	public signInWithGoogle(
		googleToken: string,
		language: Language,
		referralId?: string
	): Promise<ClientUser> {
		return this.http
			.post<UserWithoutSensitive>(this.url('social', 'google'), {
				googleToken,
				language,
				referralId,
			})
			.then(userTransformer);
	}

	public signInWithApple(
		result: SignInWithAppleResponse,
		language: Language,
		referralId?: string
	): Promise<ClientUser> {
		const body = {
			idToken: result.response.identityToken,
			name: result.response.givenName,
			lastName: result.response.familyName,
			language,
			referralId,
		};
		return this.http
			.post<UserWithoutSensitive>(this.url('social', 'apple'), body)
			.then(userTransformer);
	}

	public signInWithMicrosoft(
		accessToken: string,
		language: Language,
		referralId?: string
	): Promise<ClientUser> {
		const body = {
			accessToken,
			language,
			referralId,
		};
		return this.http
			.post<UserWithoutSensitive>(this.url('social', 'azure'), body)
			.then(userTransformer);
	}

	public completeRegistration(data: CompleteRegistrationBody): Promise<ClientUser> {
		return this.http
			.post<CompleteRegistrationResponse>(this.url('complete-registration'), data)
			.then(userTransformer);
	}

	public completeOnboarding(): Promise<void> {
		return this.http.post<void>(this.url('complete-onboarding'));
	}

	public resendConfirmationEmail(): Promise<void> {
		return this.http.post<void>(this.url('resend-confirmation-email'));
	}

	public verifyEmail(token: string): Promise<void> {
		return this.http.patch<void>(
			this.url('verify-email'),
			{ emailVerified: true },
			{},
			{ 'x-access-token': token }
		);
	}
}
