export enum ClientEnvironmentName {
	PRODUCTION = 'production',
	STAGING = 'staging',
	DEVELOPMENT = 'development',
	LOCAL = 'local',
}

export interface ClientEnvironment {
	production: boolean;

	environmentName: ClientEnvironmentName;

	sentryDSN?: string;

	mixpanelToken: string;

	frontendUrl: string;

	apiBaseUrl: string;

	socialSignIn: {
		googleClientId: string;
		appleClientId: string;
		microsoftClientId: string;
	};

	landingUrl: string;

	googleAdsId?: string;

	googleAnalyticsId?: string;

	zapierAppLink?: string;

	imageCdn?: string;

	googleMapsApiKey?: string;

	discourseUrl: string;
	handbookUrl: string;
	organizationDashboardUrl: string;
}
