import { RegisterMethod } from '../auth/';
import { ClientMetadataIsNative, ClientMetadataOs } from '../client-metadata';
import { ReachIntegration } from '../integrations';
import { NodeType, SourceEnum } from '../nodes';
import { NoteTemplate } from '../note-templates';
import { Nullable } from '../typescript';
import { CountryCode } from './country.enum';
import { Gender } from './gender.enum';
import { Interest } from './interests.enum';
import { LastAcceptedPrivacyPolicyInterface } from './last-accepted-privacy.interface';
import { LastAcceptedTermsAndConditionsInterface } from './last-accepted-terms-and-conditions.interface';
import { Profession } from './profession.enum';
import { UserRegisteredSource } from './user-registered-source.types';
import { UserType } from './user-type.enum';
import { UserSummaryInfo } from './users-summary.interface';
import { Language } from './language.enum';
import { Theme } from '../settings';
import { SubscriptionPreferences } from './preferences.interface';
import { tagNodesSubtypes } from '../node-subtypes';

export interface GetEmailSubscriptionUsersResponse {
	users: EmailSubscriptionUsersInterface[];
	totalCounts: UsersSummaryCount;
}

export interface EmailSubscriptionUsersInterface {
	id: number;
	email: string;
	subscribedAt: Date;
	ip: string;
}

export interface GetUsersInfoResponse {
	users: UserListItemInterface[];
	totalCounts: UsersSummaryCount;
}

export interface GetDeletedUsersInfoResponse {
	users: DeletedUserListItemInterface[];
	totalCounts: UsersSummaryCount;
}
export type UsersSummaryCount = { [type in UserSummaryInfo]: number };

export type UsersSummaryTagSubtypeCountType = {
	[type in (typeof tagNodesSubtypes)[number]]: number;
} & { OTHER: number };

export interface UserListItemInterface {
	privateId: string;
	username: string;
	email: string;
	fullName: string;
	gender: Gender;
	birthday: Date;
	country: CountryCode;
	interests: Interest[];
	hearing: string;
	type: UserType;
	registerLocation: string;
	registeredAt: Date;
	activatedAt: Nullable<Date>;
	subscribedAt: Nullable<Date>;
	lastAccess: Nullable<Date>;
	registerIP: string;
	registeredSource: UserRegisteredSource;
	registeredOS: ClientMetadataOs;
	registeredNative: ClientMetadataIsNative;
	lastAcceptedTermsAndConditions: LastAcceptedTermsAndConditionsInterface;
	lastAcceptedPrivacyPolicy: LastAcceptedPrivacyPolicyInterface;
	reachAuth: boolean;
	googleAuth: boolean;
	appleAuth: boolean;
	profession: Profession | null;
	pushSubscriptions: SubscriptionPreferences;
	emailSubscriptions: SubscriptionPreferences;
	integrations: { [id in ReachIntegration]: number };
	totalIntegrationsCount: number;
	language: Language;
	theme: Theme;
	countGooglePlacesDetailsRequest: number;
	countGooglePlacesSearchRequest: number;
	nodeCountPerType: Nullable<{ [key in NodeType | 'TOTAL']: number }>;
	visualNodeCountPerType: Nullable<{ [key in NodeType | 'TOTAL']: number }>;
	totalMemoryFiles: number;
	totalAlbums: number;
	totalMemoryAttachments: number;
	totalMemory: number;
	totalEdges: number;
	userCreatedEdges: number;
	referencedEdges: number;
	domainEdges: number;
	numNodesCreatedLastDay: number;
	numNodesCreatedLastWeek: number;
	numNodesCreatedLastMonth: number;
	numNodesCreatedLastQuarter: number;
	tagSubtypeNodeCount: Nullable<UsersSummaryTagSubtypeCountType>;
	nodesSourceMap: Nullable<Record<SourceEnum, number>>;
	numNodesFromIntegrations: number;
	numEnigmasAnsweredCorrectly: number;
	numEnigmasAnsweredIncorrectly: number;
	totalUsageOfCustomizedProperties: number;
	totalNumberOfCustomizedPropertiesCreated: number;
	totalCreatedOrModifiedProperties: number;
	numAiChars: number;
	numAiWords: number;
	numAiMessages: number;
}

export interface DeletedUserListItemInterface {
	id: string;
	birthday: Nullable<Date>;
	country: Nullable<CountryCode>;
	profession: Nullable<Profession>;
	hearing: Nullable<string>;
	registerLocation: Nullable<string>;
	registerIP: Nullable<string>;
	registeredAt: Nullable<Date>;
	activatedAt: Nullable<Date>;
	lastAccess: Nullable<Date>;
	deletedAt: Date;
	type: UserType;
	gender: Nullable<Gender>;
	interests: Nullable<Interest[]>;
	selectedNoteTemplates: NoteTemplate[];
	couldLogInWithPassword: boolean;
	couldLogInWithGoogle: boolean;
	couldLogInWithApple: boolean;
	registerMethod: RegisterMethod;
	completedOnboarding: boolean;
	integrations: { [id in ReachIntegration]: number };
	registeredSource: UserRegisteredSource;
	registeredOS: ClientMetadataOs;
	registeredNative: ClientMetadataIsNative;
	nodeCountPerType: Nullable<{ [key in NodeType | 'TOTAL']: number }>;
	visualNodeCountPerType: Nullable<{ [key in NodeType | 'TOTAL']: number }>;
	subscribedToEmails: boolean;
}
