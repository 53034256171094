import { QuotaSlotInterface, QuotaTypeEnum } from '../quotas';

export type AddQuotaSlotsResponse = QuotaSlotInterface;
export type UpdateQuotaSlotsResponse = QuotaSlotInterface;
export type GetQuotaSlotsPerUserResponse = { [key in QuotaTypeEnum]: QuotaSlotInterface[] };

export type AddQuotaSlotBody = Omit<QuotaSlotInterface, 'id' | 'createdAt'>;
export type UpdateQuotaSlotBody = Omit<
	QuotaSlotInterface,
	'id' | 'userId' | 'createdAt' | 'source' | 'type'
>;

export type GetQuotaSlotsPerUserBody = { privateId: string };
