import { Nullable } from '../typescript';
import { ReachIntegration } from './integration-type.enum';

export interface IntegrationSyncSelectionInterface {
	id: string;
	integrationType: ReachIntegration;
	integrationId: string;
	isContainer: boolean;
	path: Nullable<string[]>;
	externalId?: Nullable<string>;
	updatedAt: Nullable<Date>;
}
