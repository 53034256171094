import { Nullable } from '../../typescript';
import { IntegrationSyncSelectionInterface } from '../integration-sync-selection.interface';
import { ReachIntegration } from '../integration-type.enum';

export interface GoogleDriveSyncSelectionInterface
	extends Omit<IntegrationSyncSelectionInterface, 'integrationType' | 'externalId'> {
	integrationType: ReachIntegration.GOOGLE_DRIVE;
	externalId: Exclude<IntegrationSyncSelectionInterface['externalId'], null | undefined>;
	namesPath: Nullable<string[]>;
}
