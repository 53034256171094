export enum UserListSortByEnum {
	PRIVATEID = 'privateId',
	USERNAME = 'username',
	EMAIL = 'email',
	FULLNAME = 'fullName',
	HEARING = 'hearing',
	PROFESSION = 'profession',
	BIRTHDAY = 'birthday',
	GENDER = 'gender',
	INTERESTS = 'interests',
	COUNTRY = 'country',
	TYPE = 'type',
	REGISTERLOCATION = 'registerLocation',
	REGISTERIP = 'registerIP',
	REGISTEREDSOURCE = 'registeredSource',
	REGISTEREDOS = 'registeredOS',
	REGISTEREDNATIVE = 'registeredNative',
	REGISTEREDAT = 'registeredAt',
	ACTIVATEDAT = 'activatedAt',
	SUBSCRIBEDAT = 'subscribedAt',
	LASTACCESS = 'lastAccess',
	ID = 'id',
	IP = 'ip',
	LANGUAGE = 'language',
	THEME = 'theme',
	CREDITS_AMOUNT = 'creditsAmount',
	UNCLAIMED_CREDITS_AMOUNT = 'unclaimedCreditsAmount',
	NUM_EXPERIENCE_POINTS = 'numExperiencePoints',
	COUNT_GOOGLE_PLACES_DETAILS_REQUEST = 'countGooglePlacesDetailsRequest',
	COUNT_GOOGLE_PLACES_SEARCH_REQUEST = 'countGooglePlacesSearchRequest',
	TOTAL_INTEGRATIONS_COUNT = 'totalIntegrationsCount',
	VISUAL_NODES = 'visualNodes',
	VISUAL_FILES = 'visualFiles',
	VISUAL_WORKSPACES = 'visualWorkspaces',
	VISUAL_NOTES = 'visualNotes',
	VISUAL_WEBSITES = 'visualWebsites',
	VISUAL_TAGS = 'visualTags',
	VISUAL_ALBUMS = 'visualAlbums',
	ALL_NODES = 'allNodes',
	ALL_FILES = 'allFiles',
	ALL_WORKSPACES = 'allWorkspaces',
	ALL_NOTES = 'allNotes',
	ALL_WEBSITES = 'allWebsites',
	ALL_TAGS = 'allTags',
	ALL_ALBUMS = 'allAlbums',
	TAG_COMPANY_COUNT = 'tagCompanyCount',
	TAG_CONTACT_COUNT = 'tagContactCount',
	TAG_PLACE_COUNT = 'tagPlaceCount',
	TAG_TOPIC_COUNT = 'tagTopicCount',
	TAG_OTHER_COUNT = 'tagOtherCount',
	TOTAL_MEMORY_FILES = 'totalFiles',
	TOTAL_ALBUMS = 'totalAlbums',
	TOTAL_MEMORY_ATTACHMENTS = 'totalAttachments',
	TOTAL_MEMORY = 'totalMemory',
	BESTDESCRIBES = 'bestDescribes',
	STARTINGWORKSPACES = 'registrationTemplate',
	DELETED_AT = 'deletedAt',
	CATEGORY = 'subcategory',
	DIFFICULTY = 'difficulty',
	TIME = 'timeLimitMs',
	QUESTIONS = 'title',
	CORRECT_ANSWER = 'answers',
	CORRECT_ANSWER_2 = 'answers',
	CORRECT_ANSWER_3 = 'answers',
	CORRECT_ANSWER_4 = 'answers',
	WRONG_ANSWER = 'answers',
	WRONG_ANSWER_2 = 'answers',
	WRONG_ANSWER_3 = 'answers',
	CORRECT_COUNT = 'numEnigmasAnsweredCorrectly',
	WRONG_COUNT = 'numEnigmasAnsweredIncorrectly',
	TIMEOUT_COUNT = 'numTimedOut',
	NODES_CREATED_LAST_DAY = 'numNodesCreatedLastDay',
	NODES_CREATED_LAST_WEEK = 'numNodesCreatedLastWeek',
	NODES_CREATED_LAST_MONTH = 'numNodesCreatedLastMonth',
	NODES_CREATED_LAST_QUARTER = 'numNodesCreatedLastQuarter',

	SOURCE_IMPORT = 'IMPORT',
	SOURCE_SAMPLE = 'SAMPLE',
	SOURCE_AUTO = 'AUTO',
	SOURCE_PLUGIN = 'PLUGIN',
	SOURCE_POCKET = 'POCKET',
	SOURCE_PHONE_CONTACTS = 'PHONE_CONTACTS',
	SOURCE_BOOKMARKS = 'BOOKMARKS',
	SOURCE_NOTE_ATTACHMENT = 'NOTE_ATTACHMENT',
	SOURCE_MOBILE = 'MOBILE',
	SOURCE_DESKTOP = 'DESKTOP',
	SOURCE_UNKNOWN = 'UNKNOWN',
	SOURCE_DEFAULT_CREATION = 'DEFAULT_CREATION',
	SOURCE_FAST_CREATION = 'FAST_CREATION',
	SOURCE_RECCOMENDATION_CREATION = 'RECCOMENDATION_CREATION',
	SOURCE_SHARE_CREATION = 'SHARE_CREATION',
	SOURCE_ZAPIER_ACTION = 'ZAPIER_ACTION',
	SOURCE_CSV = 'CSV',
	SOURCE_IMPORT_EVERNOTE = 'IMPORT_EVERNOTE',
	SOURCE_NODE_SUBTYPES_NODES_TO_CREATE = 'NODE_SUBTYPES_NODES_TO_CREATE',
	SOURCE_EXTRACTED_CANDIDATE = 'EXTRACTED_CANDIDATE',
	SOURCE_PROPERTY_VALUE = 'PROPERTY_VALUE',
	SOURCE_NOTE_FROM_TRANSCRIPTION = 'NOTE_FROM_TRANSCRIPTION',
	SOURCE_NOTE_FROM_WORD_DOCX = 'NOTE_FROM_WORD_DOCX',
	SOURCE_NOTE_FROM_MARKDOWN = 'SOURCE_NOTE_FROM_MARKDOWN',
	SOURCE_GOOGLE_DOCS = 'SOURCE_GOOGLE_DOCS',

	SOURCE_GOOGLE_DRIVE = 'SOURCE_GOOGLE_DRIVE',
	SOURCE_DROPBOX_PAPER = 'SOURCE_DROPBOX_PAPER',

	SOURCE_QUIP = 'SOURCE_QUIP',
	SOURCE_CHROME = 'CHROME',
	SOURCE_FIREFOX = 'FIREFOX',

	NUM_NODES_FROM_INTEGRATIONS = 'numNodesFromIntegrations',
	TYPEFORM_BEFORE_2023_USERS = 'formsCompletion?.LEGACY_USERS_BEFORE_2023',
	TYPEFORM_REGISTRATION = 'formsCompletion?.NEW_USER',
	TYPEFORM_ADVENTURER = 'formsCompletion?.RANK_UP_TO_ADVENTURER',
	TYPEFORM_EXPLORER = 'formsCompletion?.RANK_UP_TO_EXPLORER',
	TYPEFORM_PIONEER = 'formsCompletion?.RANK_UP_TO_PIONEER',
	TYPEFORM_VISIONARY = 'formsCompletion?.RANK_UP_TO_VISIONARY',
	TYPEFORM_MASTER = 'formsCompletion?.RANK_UP_TO_MASTER',
	TYPEFORM_EXPERT = 'formsCompletion?.RANK_UP_TO_EXPERT',
	NUM_ENIGMAS_ANSWERED_CORRECTLY = 'NUM_ENIGMAS_ANSWERED_CORRECTLY',
	NUM_ENIGMAS_ANSWERED_INCORRECTLY = 'NUM_ENIGMAS_ANSWERED_INCORRECTLY',
	TOTAL_EDGES = 'totalEdges',
	USER_CREATED_EDGES = 'userCreatedEdges',
	REFERENCED_EDGES = 'referencedEdges',
	DOMAIN_EDGES = 'domainEdges',

	TOTAL_CREATED_OR_MODIFIED_PROPERTIES = 'totalCreatedOrModifiedProperties',
	TOTAL_CUSTOMIZED_PROPERTIES_CREATED = 'totalNumberOfCustomizedPropertiesCreated',
	TOTAL_USAGE_OF_CUSTOMIZED_PROPERTIES = 'totalUsageOfCustomizedProperties',

	NUM_AI_CHARS = 'numAiChars',
	NUM_AI_WORDS = 'numAiWords',
	NUM_AI_MESSAGES = 'numAiMessages',
}
