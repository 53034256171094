import { QuotaTypeEnum } from './quota-type.enum';
import { QuotaSlotSourceEnum } from './quota-slot-source.enum';

export interface QuotaSlotInterface {
	id: number;
	userId: string;
	amount: number;
	type: QuotaTypeEnum;
	source: QuotaSlotSourceEnum;
	note: string | null;
	createdAt: Date;
	validFrom: Date | null;
	validUntil: Date | null;
}
