import { ChatWidgetSystemPayloadEnum } from './chat-widget-system-payload.enum';
import { ChatWidgetEmailNotificationInterface } from './chat-widget-email-notification-data';

export interface ChatWidgetSystemPayloadInterface {
	payloadType: ChatWidgetSystemPayloadEnum;
	text?: string;
	redirect?: string;
	reactionEmail?: string;
	notification?: ChatWidgetEmailNotificationInterface;
}
