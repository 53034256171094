import { Nullable } from '../typescript';
import { ToolEnum, ChatToolUsageInterface } from './chat-tool.types';
import { ChatMessageStatusEnum } from './chat.types';

export interface ChatMessageResponseInterface {
	rating: Nullable<number>; // 0 or 1
	status: ChatMessageStatusEnum;
	rawAnswer: string;
	answer: string;
	timestamp: number;
	toolsUsed: ChatToolUsageInterface<ToolEnum>[]; // Use generic tool usage interface
	suggestions: string[];
}

export interface ChatMessageInterface {
	id: string;
	userPrivateId: string;
	question: string;
	timestamp: number;
	deleted: boolean;
	useMemory: boolean;
	response: ChatMessageResponseInterface;
}
