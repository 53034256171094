import { CountryCode } from './country.enum';
import { Gender } from './gender.enum';
import { Interest } from './interests.enum';
import { Profession } from './profession.enum';

export interface UserUpdateInterface {
	fullName?: string;

	birthday?: Date;

	country?: CountryCode;

	profession?: Profession;

	gender?: Gender;

	interests?: Interest[];
}

export interface UserConflictsQuery {
	username?: string | undefined;
	email?: string | undefined;
}

export interface DeletionMotiveBody {
	motives: string[];
}
